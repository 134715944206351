import { Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import ProductDetail from "./pages/productDetail/ProductDetail";
import Cart from "./pages/cart/Cart";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./locales/translationsEn";
import translationsUz from "./locales/translationsUz";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEn },
    uz: { translation: translationsUz },
  },
  lng: "uz",
  fallbackLng: "uz",
});

function App() {
  const changeLang = (value) => {
    i18n.changeLanguage(value);
  };

  return (
    <>
      <Header changeLang={changeLang} />
      <Routes>
        <Route path="/" element={<Home  />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
      </Routes>
    </>
  );
}

export default App;
