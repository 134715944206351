import "./searchbar.css";
import { GoSearch } from "react-icons/go";

const Searchbar = () => {
  return (
    <> 
        <input
          className="search-input"
          type="search"
          placeholder="Search"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <span className="search_icon">
          <GoSearch className="search-icon" />
        </span>
    
    </>
  );
};

export default Searchbar;
