import "./banner.css";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

const Banner = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 p-0">
          <div className="corusell">
            <Swiper 
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 5500,
                disableOnInteraction: false,
              }}
              // pagination={{
              //   clickable: true,
              //   screenLeft: true,
              // }}
              modules={[Autoplay, Pagination]}
              className="mySwiper banner-swiper"
            >
              <SwiperSlide className="main">
                <img
                  className="corusel-img"
                  src={require("../../assets/main/slide1.jpg")}
                  alt="img"
                />
                <div className="corusel-title">
                  <h5>OFFICE FIT</h5>
                  <p>FOR YOUR HEALTH</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="corusel-img"
                  src={require("../../assets/main/slide2.jpg")}
                  alt="img"
                />
                <div className="corusel-title">
                  <h5>OFFICE FIT</h5>
                  <p>FOR YOUR HEALTH</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="corusel-img"
                  src={require("../../assets/main/slide3.jpg")}
                  alt="img"
                />
                <div className="corusel-title">
                  <h5>OFFICE FIT</h5>
                  <p>FOR YOUR HEALTH</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="corusel-img"
                  src={require("../../assets/main/slide4.jpg")}
                  alt="img"
                />
                <div className="corusel-title">
                  <h5>OFFICE FIT</h5>
                  <p>FOR YOUR HEALTH</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="corusel-img"
                  src={require("../../assets/main/slide5.jpg")}
                  alt="img"
                />
                <div className="corusel-title">
                  <h5>OFFICE FIT</h5>
                  <p>FOR YOUR HEALTH</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="col-md-3 col-sm-12"></div>
      </div>
    </div>
  );
};

export default Banner;