import React from "react";
import Banner from "../../components/banner/Banner";
import Products from "../products/Products";
import Footer from "../../components/footer/Footer";

function Home() {
  return (
    <div className="home-main">
      <Banner />
      <Products />
      <Footer />
    </div>
  );
}

export default Home;
