import "./footer.css";
import { FaFacebookF } from "react-icons/fa6";
import { SiTelegram } from "react-icons/si";
import { SlSocialInstagram } from "react-icons/sl";
import { MdLocationPin } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-main ">
      <div className="container">
        <div className="row address-main">
          <div className="col-lg-4 col-sm-12 col-xs-12">
            <div className="addIcon">
              <MdLocationPin />
            </div>
            <div className="addContent">
              <h5>Adress</h5>
              <p>Tashkent, Uzbekistan</p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 col-xs-12">
            <div className="addIcon">
              <FaPhone />
            </div>
            <div className="addContent">
              <h5>Phone</h5>
              <p>
                +998930490807 <br />
                +998905430827
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 col-xs-12">
            <div className="addIcon">
              <MdMarkEmailUnread />
            </div>
            <div className="addContent">
              <h5>Email</h5>
              <p>
                <a target={"_blank"} style={{ textDecoration: "none" }}>
                  abduhalimhalmatov@gmail.com
                </a>
              </p>
            </div>
          </div>

          <div className="social-media text-center text-white">
            <div className="container p-4 pb-0">
              <section>
                {/* <!-- Facebook --> */}
                <a
                  className="facebook social-link"
                  href="https://www.facebook.com/profile.php?id=61556285553935&mibextid=2JQ9oc"
                  role="button"
                  target={"_blank"}
                >
                  <FaFacebookF />
                </a>

                {/* <!-- Telegram --> */}
                <a
                  className="telegram social-link"
                  href="https://t.me/officefituzb"
                  role="button"
                  target={"_blank"}
                >
                  <SiTelegram />
                </a>

                {/* <!-- Instagram --> */}
                <a
                  className="instagram social-link"
                  href="https://www.instagram.com/office.fit.uz?igsh=MWpzZm9tN3N4cG82Nw=="
                  role="button"
                  target={"_blank"}
                >
                  <SlSocialInstagram />
                </a>
              </section>
              {/* <!-- Section: Social media --> */}
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        {currentYear} &copy; Copyright Officefit. All Rights Reserved.
      </div>
      {/* <!-- Copyright --> */}
    </footer>
  );
}

export default Footer;
