import "./productDetail.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../../store/slice/cartSlice";
import { getProducts } from "../../store/slice/productSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductDetail() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const products = useSelector((state) => state?.products?.products);

  useEffect(() => {
    dispatch(getProducts()); 
  }, []);

  const oneItem = () => {   
    return products.filter((value) => value.id == id)[0];
  };

  const handleAddToCart = () => {
    const newItem = {
      id: oneItem().id,
      name: oneItem().name,
      price: oneItem().price,
      image: oneItem().image,
    };
    toast.success("Mahsulot savatga qoʻshildi");
    dispatch(addItem(newItem));
  };

  return (
    <>
      <div className="productInfo">
        <div className="container product-wrapper">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <Swiper
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper1  swiperr-main"
              >
                {oneItem()?.images?.map((item, index) => (
                  <SwiperSlide className="swiper_slide1" key={index}>
                    <img
                      src={"https://api.officefit.uz/" + item?.url}
                      alt="main-img"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2 swiperr-main"
              >
                {oneItem()?.images.map((item, index) => (
                  <SwiperSlide className="swiper_slide2" key={index}>
                    <img
                      src={"https://api.officefit.uz/" + item.url}
                      alt="main-img"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="product-wrap">
                <h4 className="product-info__title">{oneItem()?.title}</h4>
                <div className="product-info__detail">
                  {oneItem()?.description}
                </div>
                <div className="product-info__detail">
                  <div className="product-detail__key">Narxi:</div>
                  <div className="product-detail__text">{oneItem()?.price}</div>
                </div>
                <div className="product-info__detail">
                  <div className="product-detail__key">Ranglar:</div>
                  <div className="product-detail__text">
                    {oneItem()?.colors}
                  </div>
                </div>
                <div className="product-info__detail">
                  <div className="product-detail__key">O'lchamlar:</div>
                  <div className="product-detail__text">{oneItem()?.size}</div>
                </div>
                {oneItem()?.material !== "" ? (
                  <div className="product-info__detail">
                    <div className="product-detail__key">Material:</div>
                    <div className="product-detail__text">
                      {oneItem()?.material}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="product-detail__btn">
                  <div>
                    <button
                      className="btn btn-outline-dark w-50"
                      onClick={handleAddToCart}
                    >
                      Savatga qo'shish
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default ProductDetail;
export const selectCartItems = (state) => state.cart.items;
