import "./shoppingCart.css";
import { Link } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { useSelector } from "react-redux";

function ShoppingCart() {
  const state = useSelector((state) => state.cart);

  return (
    <div>
      <Link to="/cart">
        <FiShoppingCart className="cart-icon" />
        <sup className="purchase-length">{state.items.length}</sup>
      </Link>
    </div>
  );
}

export default ShoppingCart;
