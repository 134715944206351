import "./products.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../store/slice/productSlice";

function Products() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  return (
    <div className="products">
      <div className="container">
        <h2 className="content-title">Latest Products</h2>
        <div className="row">
          {products.map((item, index) => {
            return (
              <div className="col-lg-3 col-md-4 col-sm-12 products-col" key={index}>
                <Link to={`product/${item.id}`} className="product-link">
                  <div className="product-cardd">
                    <div className="product-img">
                      <img
                        src={"https://api.officefit.uz/" + item.image}
                        alt="product-img"
                        className="w-100"
                      />
                    </div>
                    <div className="product-content p-2">
                      <div className="product-title">{item.name}</div>
                      <span className="product-price">{item.price} sum</span>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Products;
