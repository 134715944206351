import "./cart.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCartItems } from "../productDetail/ProductDetail";
import { removeItem } from "../../store/slice/cartSlice";
import { FaTrashAlt } from "react-icons/fa";
import { MdKeyboardBackspace, MdArrowForward } from "react-icons/md";
import {
  FaCartShopping,
  FaCcMastercard,
  FaCcVisa,
  FaCcPaypal,
} from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  const handleRemoveFromCart = (id) => {
    toast.error("Mahsulot savatdan o'chirildi");
    dispatch(removeItem(id));
  };

  const subTotal = () => {
    let total = 0;
    cartItems.forEach((value) => {
      total += parseInt(value.price);
    });
    return total;
  };

  console.log("subTotal ", subTotal());

  return (
    <>
      {cartItems.length === 0 ? (
        <div className="empty-cart">
          <h1>
            <FaCartShopping />
          </h1>
          <h3>Savatda hozircha mahsulot yoʻq</h3>
          <p>
            Bosh sahifadagi to’plamlardan boshlang yoki kerakli mahsulotni
            qidiruv orqali toping
          </p>
          <button className="btn btn-primary" onClick={() => navigate("/")}>
            Bosh sahifa
          </button>
        </div>
      ) : (
        <section className="h-100 h-custom" style={{ backgroundColor: "#eee" }}>
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col">
                <div className="card">
                  <div className="card-body p-4">
                    <div className="row">
                      <div className="col-lg-7">
                        <h5 className="mb-3">
                          <Link to="/" className="text-body">
                            <MdKeyboardBackspace className="fs-3" />
                            <span className="px-2">Continue shopping</span>
                          </Link>
                        </h5>
                        <hr />

                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <p className="mb-1">Shopping cart</p>
                          </div>
                          <div>
                            <p className="mb-0">
                              You have {cartItems.length} items in your cart
                            </p>
                          </div>
                        </div>
                        {cartItems.map((item, index) => (
                          <div className="card mb-3" key={index}>
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  <div>
                                    <img
                                      src={
                                        "https://api.officefit.uz/" + item.image
                                      }
                                      alt="Shopping item"
                                      className="img-fluid rounded-3"
                                      style={{ width: "65px", height: "65px" }}
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <h5>{item.name}</h5>
                                    <p className="small mb-0">
                                      small description here
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                  <div style={{ width: "50px" }}>
                                    <h5 className="fw-normal mb-0">2</h5>
                                  </div>
                                  <div style={{ width: "130px" }}>
                                    <h5 className="mb-0">{item.price}</h5>
                                  </div>
                                  <a href="#!" style={{ color: "#cecece" }}>
                                    <FaTrashAlt
                                      onClick={() =>
                                        handleRemoveFromCart(item.id)
                                      }
                                      style={{ color: "#000" }}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-lg-5">
                        <div className="card rounded-3">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h5 className="mb-0">Card details</h5>
                            </div>

                            <p className="small mb-2">Card type</p>
                            <a href="#!" type="submit">
                              <FaCcMastercard className="fs-1" />
                            </a>
                            <a href="#!" type="submit">
                              <FaCcVisa className="fs-1 mx-3" />
                            </a>
                            <a href="#!" type="submit">
                              <FaCcPaypal className="fs-1" />
                            </a>

                            <form className="mt-4">
                              <div className="form-outline form-white mb-4">
                                <input
                                  type="text"
                                  // id="typeName"
                                  className="form-control form-control-lg"
                                  placeholder="Cardholder's Name"
                                />
                              </div>

                              <div className="form-outline form-white mb-4">
                                <input
                                  type="text"
                                  // id="typeText"
                                  className="form-control form-control-lg"
                                  placeholder="1234 5678 9012 3457"
                                  minLength="19"
                                  maxLength="19"
                                />
                              </div>

                              <div className="row mb-4">
                                <div className="col-md-6">
                                  <div className="form-outline form-white">
                                    <input
                                      type="text"
                                      id="typeExp"
                                      className="form-control form-control-lg"
                                      placeholder="MM/YYYY"
                                      size="7"
                                      minLength="7"
                                      maxLength="7"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-outline form-white">
                                    <input
                                      type="password"
                                      autoComplete="on"
                                      className="form-control form-control-lg"
                                      placeholder="&#9679;&#9679;&#9679;"
                                      minLength="3"
                                      maxLength="3"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>

                            <hr className="my-4" />

                            <div className="d-flex justify-content-between">
                              <p className="mb-2">Subtotal</p>

                              {/* <p className="mb-2">{subTotal()}.sum</p> */}
                              <p className="mb-2">{subTotal()},000 so'm</p>
                            </div>

                            <div className="d-flex justify-content-between">
                              <p className="mb-2">Shipping</p>
                              <p className="mb-2">100,000 so'm</p>
                            </div>

                            <div className="d-flex justify-content-between mb-4">
                              <p className="mb-2">Total(Incl. taxes)</p>
                              <p>{subTotal() + 100},000 so'm</p>
                              {/* <p className="mb-2">{subTotal() + 100}.sum</p> */}
                            </div>

                            <button
                              type="button"
                              className="w-100 btn btn-outline-dark btn-block btn-lg"
                            >
                              <div className="d-flex justify-content-between">
                                {/* <span>{subTotal() + 100}</span> */}
                                <span>{subTotal() + 100},000 so'm</span>
                                <span>
                                  Checkout <MdArrowForward />
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <ToastContainer />
          </div>
        </section>
      )}
    </>
  );
}

export default Cart;
