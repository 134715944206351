import "./header.css";
import logo from "../../assets/logos/logo.jpg";
import { FaFacebookF } from "react-icons/fa6";
import { SiTelegram } from "react-icons/si";
import { SlSocialInstagram } from "react-icons/sl";
import { PiPhoneCall } from "react-icons/pi";
import { Link } from "react-router-dom";
import Searchbar from "../searchbar/Searchbar";
import { MdOutlineCurrencyExchange } from "react-icons/md";
import ShoppingCart from "../shoppingCart/ShoppingCart";
import Signup from "../signup/Signup";
import Currency from "../currency/Currency";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { useState } from "react";

function Header({ changeLang }) {
  const [selected, setSelected] = useState("RU");
  const { t } = useTranslation();

  const changeLangHandLer = (e) => {
    // changeLang(e);
    // setSelected(e)
    changeLang(e.target.value);
  };

  return (
    <div className="header container-fluid">
      <div className="top-nav container">
        <div className="phone">
          <span className="phone-icon">
            <PiPhoneCall />
          </span>
          +998930490807
        </div>
        <div className="social-main">
          <div className="social-title">Follow us:</div>
          <a
            className="facebook"
            href="https://www.facebook.com/profile.php?id=61556285553935&mibextid=2JQ9oc"
            role="button"
            target={"_blank"}
          >
            <FaFacebookF />
          </a>
          <a
            className="telegram"
            href="https://t.me/officefituzb"
            role="button"
            target={"_blank"}
          >
            <SiTelegram />
          </a>
          <a
            className="instagram"
            href="https://www.instagram.com/office.fit.uz?igsh=MWpzZm9tN3N4cG82Nw=="
            role="button"
            target={"_blank"}
          >
            <SlSocialInstagram />
          </a>
          <div
            className="currency_icon"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <MdOutlineCurrencyExchange />
          </div>
          <div className="vl"></div>
          <select onChange={changeLangHandLer}>
            <option value="uz">O'zbekcha</option>
            <option value="ru">Русский </option>
            <option value="en">English</option>
          </select>

          {/* <ReactFlagsSelect
                    className="for-language"
                    selected={selected}
                    countries={["RU", "UZ", "US"]}
                    onSelect={(code) => changeLangHandLer(code)}
                /> */}
        </div>
      </div>
      <hr className="m-0" />
      <div className="middle-nav container">
        <Link to="/" className="navbar-brand fw-bold fs-3 logo">
          <div className="logoImg">
            <img src={logo} alt="logo" />
          </div>
          <div className="logo-name">OFFICEFIT </div>   
          {/* {t("about")} */}
        </Link>
        <div className="header-search">
          <Searchbar />
        </div>
        <div className="middle-nav-icons d-flex gap-4">
          <div>
            <Signup />
          </div>
          <div>
            <ShoppingCart />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-dark" id="exampleModalLabel">
                Valyuta konvertori
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-3">
              <Currency />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
