import "./signup.css";
import { FaRegUser } from "react-icons/fa";

function Signup() {
  return (
    <div>
      <FaRegUser
        data-bs-toggle="modal"
        data-bs-target="#loginModal"
        className="signup-icon"
      />
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Telefon raqamini kiriting</h4>
              <h6>Tasdiqlash kodini SMS orqali yuboramiz</h6>
              <form>
                <div className="mt-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="+998 00 000-00-00"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100 mt-5">
                  Tasdiqlash
                </button>
              </form>
              <br />
              <div id="emailHelp" className="form-text text-center">
                <p>
                  Avtotizatsiyadan o'tish orqali siz shaxsiy ma'lumotlarni qayta
                  ishlash siyosatiga rozilik bildirasiz
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
