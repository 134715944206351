import axios from "axios";
import React, { useEffect, useState } from "react";
import "./currency.css";

function Currency() {
  const [currencyy, setCurrncyy] = useState("");
  const [from, setFrom] = useState("USD");
  const [sum, setSum] = useState(1);

  

  const handleExchange = async () =>{
    const options = {
      method: 'GET',
      url: 'https://exchangerate-api.p.rapidapi.com/rapid/latest/USD',
      headers: {
        'X-RapidAPI-Key': 'd94a70050dmsh944fafc088cacb6p114449jsn844621118218',
        'X-RapidAPI-Host': 'exchangerate-api.p.rapidapi.com'
      }
    };
    
    try {
      const response = await axios.request(options);
      setCurrncyy(response.data.rates)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleExchange()
  }, [from])


  return (
    <div className="currency-wrapper">
      <div className="valyuta_main d-flex justify-content-between mb-3">
        <input
          className=""
          type="text"
          placeholder="Boshlang'ich qiymat (1$)"
          onChange={(e) => setSum(e.target.value)}
        />
        <select name="" id="" onChange={(e) => setFrom(e.target.value)}>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="RUB">RUB</option>
          <option value="CNY">CNY</option>
        </select>
      </div>

      <div className="currecy">
        <span>{new Intl.NumberFormat("de-DE").format((currencyy["USD"]/currencyy[from]) * currencyy["UZS"] * sum)}</span>
        <span>UZS</span>
      </div>
    </div>
  );
};

export default Currency;
